import { disableTidePoints, enableTidePoints } from '@/api/pmtidepoints';

const disableTidePoint = {
	id: 'disableTidePoints',
	selectionType: 'multiple',
	label: 'pmtidepoints.actions.disableTidePoints',
	functionality: 'UPDATE_PMTIDEPOINTS',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmtidepoints.actions.disableTidePoints');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmtidepointid: registries[i].pmtidepointid
			});
		}

		const data = await disableTidePoints(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableTidePoint = {
	id: 'enableTidePoints',
	selectionType: 'multiple',
	label: 'pmtidepoints.actions.enableTidePoints',
	functionality: 'UPDATE_PMTIDEPOINTS',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmtidepoints.actions.enableTidePoints');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmtidepointid: registries[i].pmtidepointid
			});
		}

		const data = await enableTidePoints(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableTidePoint, enableTidePoint]
};
